import {
  NavigatorFilterIdEnum,
  ProductGroupFilterIdEnum,
  SimpleFilterIdEnum,
} from '@ev-portals/dp/frontend/shared/api-client';

/**
 * We merge all 3 types of filter Ids comming from the product list endpoint
 */
export const FilterIdEnum = {
  ...SimpleFilterIdEnum,
  ...ProductGroupFilterIdEnum,
  ...NavigatorFilterIdEnum,
};

export type FilterIdEnum = SimpleFilterIdEnum | ProductGroupFilterIdEnum | NavigatorFilterIdEnum;
