import { inject, Injectable } from '@angular/core';
import { ProductDetailsDto, ProductsApiService } from '@ev-portals/dp/frontend/shared/api-client';
import {
  BehaviorSubject,
  distinctUntilChanged,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductDetailsFacade {
  #productsService = inject(ProductsApiService);
  #_loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#_loading$.asObservable();

  #_selectedProductId$ = new BehaviorSubject<string | null>(null);
  selectedProductId$ = this.#_selectedProductId$.asObservable().pipe(distinctUntilChanged());

  selectedProduct$ = this.selectedProductId$.pipe(
    switchMap(productId => {
      if (!productId) {
        return of(null);
      }

      return this.#getProductFromServer(productId);
    }),
    tap(() => this.#switchLoading(false)),
    shareReplay(1),
  );

  selectProduct(productId: string | null): void {
    this.#switchLoading(true);
    this.#_selectedProductId$.next(productId);
  }

  #switchLoading(isLoading: boolean): void {
    this.#_loading$.next(isLoading);
  }

  #getProductFromServer(productId: string): Observable<ProductDetailsDto> {
    return this.#productsService.getProductDetails({ id: productId });
  }
}
