import { Injectable } from '@angular/core';
import {
  NavigatorFilter,
  ProductGroupFilter,
  ProductGroupFilterIdEnum,
  SimpleFilter,
} from '@ev-portals/dp/frontend/shared/api-client';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupFilterService {
  // We need this just for tracking
  #productGroupOptionTitlesMap: ProductGroupFilterIdToOptionsMap = {
    [ProductGroupFilterIdEnum.FunctionsFilter]: {},
    [ProductGroupFilterIdEnum.PolymersFilter]: {},
  };

  // We cache an option id to option title map so we can get the human-readable titles for tracking
  getOptionIdToOptionNameMap(
    newAvailableFilters: (SimpleFilter | ProductGroupFilter | NavigatorFilter)[],
  ): void {
    Object.values(ProductGroupFilterIdEnum).forEach(productGroupFilterId => {
      const productGroupFilter = newAvailableFilters.find(
        newFilter => newFilter.id === productGroupFilterId,
      ) as ProductGroupFilter | undefined;

      if (productGroupFilter) {
        const { id: filterId, options } = productGroupFilter;

        options.forEach(option => {
          this.#productGroupOptionTitlesMap[filterId][option.id] = option.title;
        });
      }
    });
  }

  // called from product search component to mantain human readable option names for tracking
  getProductGroupOptionTitle(
    productGroupFilterId: ProductGroupFilterIdEnum,
    productGroupSelectedOptionId: string,
  ): string {
    const title =
      this.#productGroupOptionTitlesMap[productGroupFilterId][productGroupSelectedOptionId];

    return title;
  }
}

type ProductGroupFilterIdToOptionsMap = Record<
  ProductGroupFilterIdEnum,
  ProductGroupOptionIdToTitleMap
>;
type ProductGroupOptionIdToTitleMap = Record<string, string>;
