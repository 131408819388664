import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  NavigatorCategoryDto,
  NavigatorFilterIdEnum,
  ProductsApiService,
} from '@ev-portals/dp/frontend/shared/api-client';
import { map } from 'rxjs';

import { ProductFacade } from './product.facade';

@Injectable({ providedIn: 'root' })
export class ProductIndustryFilterService {
  rootCategoryId?: string;

  #productsApiService = inject(ProductsApiService);
  #productFacade = inject(ProductFacade);

  $rootCategoryTree = toSignal(
    this.#productsApiService.getIndustriesFilterOptions().pipe(
      map(response => {
        const { industryNavigatorFilterTree } = response;
        this.rootCategoryId = industryNavigatorFilterTree?.id;

        return industryNavigatorFilterTree;
      }),
    ),
  );

  $selectedCategoryId = toSignal(
    this.#productFacade.selectedRemoteFilters$.pipe(
      map(remoteFilters => remoteFilters[NavigatorFilterIdEnum.IndustryFilter]),
    ),
  );

  $visibleCategory = computed<NavigatorCategoryDto | null>(() =>
    this.#getCategoryOptionById(
      this.$selectedCategoryId() as string,
      this.$rootCategoryTree() as NavigatorCategoryDto,
    ),
  );

  /**
   * We need human readable option name for analytics
   */
  getOptionName(optionId: string): string | undefined {
    const option = this.#getCategoryOptionById(optionId, this.$rootCategoryTree());

    // we can assert as the selected option ID comes from the same tree
    return option?.name as string;
  }

  // A method which searches for an NavigatorCategoryDto by id recursively
  #getCategoryOptionById(
    selectedId: string | null,
    category: NavigatorCategoryDto | null | undefined,
  ): NavigatorCategoryDto | null {
    if (!category) {
      return null;
    }

    if (!selectedId || category.id === selectedId) {
      return category;
    }

    if (category.children) {
      for (const child of category.children) {
        const result = this.#getCategoryOptionById(selectedId, child);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }
}
