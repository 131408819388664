import { Injectable } from '@angular/core';
import {
  DocumentListResponseDto,
  DocumentsApiService,
} from '@ev-portals/dp/frontend/shared/api-client';
import { BehaviorSubject, Observable, of, shareReplay, switchMap, tap } from 'rxjs';

import { ProductDetailsFacade } from './product-details.facade';

@Injectable({ providedIn: 'root' })
export class ProductDocumentsFacade {
  #_loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#_loading$.asObservable();

  documentList$: Observable<DocumentListResponseDto | null> =
    this.productDetailsFacade.selectedProductId$.pipe(
      switchMap(productId => {
        if (!productId) {
          return of(null);
        }

        this.#switchLoadingDocuments(true);
        return this.#getProductDocumentsFromServer(productId);
      }),
      tap(() => this.#switchLoadingDocuments(false)),
      shareReplay(1),
    );

  constructor(
    private productDetailsFacade: ProductDetailsFacade,
    private apiService: DocumentsApiService,
  ) {}

  #getProductDocumentsFromServer(productId: string): Observable<DocumentListResponseDto> {
    // For testing
    // return timer(500).pipe(map(() => mockDocumentList));
    return this.apiService.getDocumentList({ productId });
  }

  #switchLoadingDocuments(isLoading: boolean): void {
    this.#_loading$.next(isLoading);
  }
}
