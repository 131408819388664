import { inject, Injectable, signal } from '@angular/core';
import {
  NavigatorFilter,
  ProductGroupFilter,
  ProductGroupFilterIdEnum,
  ProductListDto,
  ProductListRequestBodyDto,
  ProductsApiService,
  SimpleFilter,
} from '@ev-portals/dp/frontend/shared/api-client';
import { FeedbackMessageService, SelectedRemoteFilters } from '@ev-portals/dp/frontend/shared/util';
import { isEqual, pickBy } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import { ProductGroupFilterService } from './product-group-filter.service';

@Injectable({
  providedIn: 'root',
})
export class ProductFacade {
  #productGroupFilterService = inject(ProductGroupFilterService);
  #productsApiService = inject(ProductsApiService);
  #feedbackMessageService = inject(FeedbackMessageService);

  $loadingProductList = signal(true);

  // Search related variables
  #defaultRemoteFilters: SelectedRemoteFilters = {};
  #_selectedRemoteFilters$ = new BehaviorSubject<SelectedRemoteFilters>(this.#defaultRemoteFilters);
  selectedRemoteFilters$ = this.#_selectedRemoteFilters$
    .asObservable()
    .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
  private get selectedRemoteFilters(): SelectedRemoteFilters {
    return this.#_selectedRemoteFilters$.value;
  }

  #triggerNewSearch$ = new BehaviorSubject(true);
  productList$: Observable<ProductListDto> = this.#getProductList$();

  getProducts(remoteFilters: ProductListRequestBodyDto): Observable<ProductListDto> {
    return this.#productsApiService
      .getProductList({
        body: remoteFilters,
      })
      .pipe(
        catchError(() => {
          this.#feedbackMessageService.addErrorNotification();

          return of({ filters: [], products: [] });
        }),
      );
  }

  resetRemoteFilters(): void {
    this.updateRemoteFilters(this.#defaultRemoteFilters);
  }

  /**
   * Updates remote filters state after a user action.
   * @param filterKey
   * @param selectedIds for now there's no multiselect filters so it'll always be a single string
   */
  changeRemoteFiltersByKey(filterKey: string, newValue?: string[] | string | boolean | null): void {
    const newRemoteFilters: SelectedRemoteFilters = {
      ...this.selectedRemoteFilters,
      [filterKey]: newValue,
    };

    this.updateRemoteFilters(newRemoteFilters);
  }

  updateRemoteFilters(remoteFilters: SelectedRemoteFilters, triggerSearch = true): void {
    this.#_selectedRemoteFilters$.next(pickBy(remoteFilters, value => !!value));

    if (triggerSearch) {
      this.#triggerNewSearch$.next(true);
    }
  }

  /**
   * We refresh the list of products, whenever the categoryId or the search conditions (filters) change
   */
  #getProductList$(): Observable<ProductListDto> {
    return this.#triggerNewSearch$.pipe(
      tap(() => this.$loadingProductList.set(true)),
      debounceTime(100),
      switchMap(() => {
        return this.getProducts({
          filters: this.selectedRemoteFilters,
        });
      }),
      tap(({ filters }) => {
        // Mantain option id to option name map for tracking
        this.#productGroupFilterService.getOptionIdToOptionNameMap(filters);
        // Silently remove selected options, which are not available anymore
        this.#purgeRemoteFilters(filters);
      }),
      tap(() => this.$loadingProductList.set(false)),
      shareReplay(1),
    );
  }

  /**
   * As a filter gets activated, some other may get inactivated, which we have to turn off automatically
   */
  #purgeRemoteFilters(
    newAvailableFilters: (SimpleFilter | ProductGroupFilter | NavigatorFilter)[],
  ): void {
    // first we copy current state
    const newRemoteFilters = {
      ...this.selectedRemoteFilters,
    };

    // now we iterate of existing selected remote filters and purge if selected ids or options are not present in backend response
    for (const [id, value] of Object.entries(this.selectedRemoteFilters)) {
      const selectedId = id as keyof SelectedRemoteFilters;

      // find if selected filters is still an available filter.
      const newFilter = newAvailableFilters.find(filter => filter.id === selectedId);

      // if was not found in new available filters, we purge it (unselect it).
      if (!newFilter) {
        delete newRemoteFilters[selectedId as keyof SelectedRemoteFilters];
      }

      // for product group we need to check that no option is selected that is no available anymore
      if ((Object.values(ProductGroupFilterIdEnum) as string[]).includes(selectedId)) {
        const selectedValue = value as string;

        const productGroupFilter = newFilter as ProductGroupFilter | undefined;
        const newOptions = productGroupFilter?.options.map(option => option.id);

        // if new options don't include previously selected option we purge it
        if (!newOptions?.includes(selectedValue)) {
          delete newRemoteFilters[selectedId];
        }
      }
    }

    this.updateRemoteFilters(newRemoteFilters, false);
  }
}
