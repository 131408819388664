import { inject, Injectable } from '@angular/core';
import { ProductDto, SimpleFilterIdEnum } from '@ev-portals/dp/frontend/shared/api-client';
import { SelectedRemoteFilters } from '@ev-portals/dp/frontend/shared/util';
// import { AnalyticsService } from '@ev-portals/dp/frontend/shared/util';
import Fuse from 'fuse.js';
import { pickBy } from 'lodash';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  Subject,
} from 'rxjs';

import { AllSelectedFilters, SelectedLocalFilters } from './model';
import { ProductFacade } from './product.facade';

@Injectable({
  providedIn: 'root',
})
export class ProductFilterService {
  #productFacade = inject(ProductFacade);
  // private analyticsService = inject(AnalyticsService);

  // loading
  loadingProductList = this.#productFacade.$loadingProductList;

  // reset filters
  #_resetFilters$ = new Subject<void>();
  resetFilters$ = this.#_resetFilters$.asObservable();
  resetView(): void {
    this.#_resetFilters$.next();
  }

  // Selected local filters
  #defaultLocalFilters: SelectedLocalFilters = {};
  #_selectedLocalFilters$ = new BehaviorSubject<SelectedLocalFilters>(this.#defaultLocalFilters);
  selectedLocalFilters$ = this.#_selectedLocalFilters$.asObservable();
  private get selectedLocalFilters(): SelectedLocalFilters {
    return this.#_selectedLocalFilters$.value;
  }

  // Search
  #_searchTerm$ = new Subject<string>();

  // Combine local and remote filters into one object
  #_allSelecteFilters$ = this.#getAllSelectedFilters$();
  allSelectedFilters$ = this.#_allSelecteFilters$.pipe(debounceTime(100));

  // Check the documentation for more info: https://fusejs.io/
  #fuseOptions = {
    includeScore: true,
    keys: ['name'],
    threshold: 0.55,
  };

  numberOfSelectedFilters$ = this.#getNumberOfSelectedFilters$();

  // Array of available filters from Backend
  availableFilters$ = this.#productFacade.productList$.pipe(
    map(productList => productList.filters),
  );
  filteredProducts$ = this.#getFilteredProducts$();

  constructor() {
    this.#listenToSearchTermChanges();
  }

  resetFilters(): void {
    // reset local filters
    this.resetLocalFilters();
    // reset remote filters
    this.#productFacade.resetRemoteFilters();
  }

  resetLocalFilters(): void {
    this.updateLocalFilters(this.#defaultLocalFilters);
  }

  #getAllSelectedFilters$(): Observable<AllSelectedFilters> {
    return combineLatest([
      this.selectedLocalFilters$,
      this.#productFacade.selectedRemoteFilters$,
    ]).pipe(
      map(([localFilters, remoteFilters]) => ({
        ...localFilters,
        ...remoteFilters,
      })),
    );
  }

  #listenToSearchTermChanges(): void {
    this.#_searchTerm$.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(searchTerm => {
      // Let's track a new searchTerm!
      // this.analyticsService.trackSearch(searchTerm);

      // Update the local filters
      this.changeLocalFiltersByKey(SimpleFilterIdEnum.SearchFilter, searchTerm);
    });
  }

  /**
   * These are 'remoteFilters', they are triggering new backend requests
   */
  changeRemoteFiltersByKey(
    filterKey: keyof SelectedRemoteFilters,
    newValue?: string[] | string | boolean | null,
  ): void {
    this.#productFacade.changeRemoteFiltersByKey(filterKey, newValue);
  }

  /**
   * These are 'localFilters', they are NOT triggering new backend requests
   */
  changeLocalFiltersByKey(
    filterKey: keyof SelectedLocalFilters,
    newValue?: string | undefined,
  ): void {
    this.#patchLocalFilters({
      [filterKey]: newValue,
    });
  }

  changeSearchTerm(searchTerm: string): void {
    this.#_searchTerm$.next(searchTerm);
  }

  #patchLocalFilters(partialLocalFilters: SelectedLocalFilters): void {
    const newLocalFilters: SelectedLocalFilters = {
      ...this.selectedLocalFilters,
      ...partialLocalFilters,
    };

    this.updateLocalFilters(pickBy(newLocalFilters, value => !!value));
  }

  updateLocalFilters(localFilters: SelectedLocalFilters): void {
    this.#_selectedLocalFilters$.next(localFilters);
  }

  #applyLocalFilters(
    products: ProductDto[],
    selectedLocalFilters: SelectedLocalFilters,
  ): ProductDto[] {
    let filteredProducts: ProductDto[] | undefined = products;
    // if search is selected
    if (selectedLocalFilters.SearchFilter) {
      filteredProducts = this.#fuzzySearch(products, selectedLocalFilters.SearchFilter);
    }

    return filteredProducts;
  }

  #fuzzySearch(products: ProductDto[], searchTerm: string): ProductDto[] {
    const fuse = new Fuse(products, this.#fuseOptions);
    const results = fuse.search(searchTerm);

    return results.map(result => result.item);
  }

  #getFilteredProducts$(): Observable<ProductDto[]> {
    return combineLatest([this.#productFacade.productList$, this.selectedLocalFilters$]).pipe(
      map(([productList, localFilters]) =>
        this.#applyLocalFilters(productList.products, localFilters),
      ),
      shareReplay(1),
    );
  }

  #getNumberOfSelectedFilters$(): Observable<number> {
    return this.#_allSelecteFilters$.pipe(
      map(allSelectedFilters => {
        return this.#getNumberOfSelectedFilters(allSelectedFilters);
      }),
      distinctUntilChanged(),
    );
  }

  #getNumberOfSelectedFilters(allSelectedFilters: AllSelectedFilters): number {
    if (!allSelectedFilters) return 0;
    // values can be undefined boolean string or string[]
    const numberOfSelectedFilters = Object.values(allSelectedFilters)
      // each option of a multi-select count as selected filter in the UI, so we flatten out
      .flat()
      .filter((value: boolean | string) => value !== undefined && value !== null).length;

    return numberOfSelectedFilters;
  }
}
