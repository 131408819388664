import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { SelectedRemoteFilters } from '@ev-portals/dp/frontend/shared/util';
import { combineLatest, debounceTime, first, firstValueFrom, map } from 'rxjs';

import { FilterIdEnum, SelectedLocalFilters } from './model';
import { ProductFacade } from './product.facade';
import { ProductFilterService } from './product-filter.service';

/**
 * Important Note!
 *
 * During caching the search filters, we're working with 3 different data stuctures:
 * 1. SearchParams - pure JSON objects, used by our services and facades.
 * 2. SearchQueryParams - JSON objects, where keys indicate filter types, but values are stringified.
 * 3. QueryString - fully stringified query params, used by the URL, could represent the above 2 data structures.
 *  This structure is also what we cache on the backend.
 */

@Injectable({
  providedIn: 'root',
})
export class CacheSearchService {
  #productFilterService = inject(ProductFilterService);
  #productFacade = inject(ProductFacade);
  #router = inject(Router);
  #activatedRoute = inject(ActivatedRoute);
  #authenticationService = inject(AuthenticationService);
  #destroyRef = inject(DestroyRef);

  /**
   * This method is called, whenever the '/products' page is visited
   */
  async syncQueryParams(queryParams: SearchQueryParams): Promise<void> {
    // Check incoming query params first
    let searchParams = this.#searchQueryParamsToSearchParams(queryParams);

    // If no query params available, parse the params from the DB
    if (this.#searchParamsIsEmpty(searchParams)) {
      const queryString$ = this.#authenticationService.user$.pipe(
        first(),
        map(user => user?.productSearchQueryString),
      );
      const queryString = await firstValueFrom(queryString$);

      if (queryString) {
        const queryParamsObject = this.#queryStringToSearchQueryParams(queryString);

        searchParams = this.#searchQueryParamsToSearchParams(queryParamsObject);
      }
    }

    const { localFilters, remoteFilters } = searchParams;

    // If query params available: init local state from url
    this.#initFilterStateFromUrlParams({
      localFilters,
      remoteFilters,
    });

    // We start listening to filter changes, and update the URL and the DB based on that
    this.setupCachingListeners();
  }

  #searchParamsIsEmpty(searchParams: SearchParams): boolean {
    const { localFilters, remoteFilters } = searchParams;

    return !localFilters && !remoteFilters;
  }

  #initFilterStateFromUrlParams(searchParams: SearchParams): void {
    if (searchParams.localFilters) {
      this.#cleanupDeprecatedUrlFilters(searchParams.localFilters);
      this.#productFilterService.updateLocalFilters(searchParams.localFilters);
    } else {
      this.#productFilterService.resetLocalFilters();
    }

    if (searchParams.remoteFilters) {
      this.#cleanupDeprecatedUrlFilters(searchParams.remoteFilters);
      this.#productFacade.updateRemoteFilters(searchParams.remoteFilters);
    } else {
      this.#productFacade.resetRemoteFilters();
    }
  }

  #searchQueryParamsToSearchParams(queryParams: SearchQueryParams): SearchParams {
    return {
      localFilters: this.#parseParam(queryParams.localFilters),
      remoteFilters: this.#parseParam(queryParams.remoteFilters),
    } as SearchParams;
  }

  #parseParam(param: string | undefined): unknown | null {
    return param ? JSON.parse(param) : null;
  }

  /**
   * Whenever we change the filters, we get notified, change the url and save it to the DB
   */
  setupCachingListeners(): void {
    combineLatest([
      this.#productFilterService.selectedLocalFilters$,
      this.#productFacade.selectedRemoteFilters$,
    ])
      .pipe(debounceTime(100), takeUntilDestroyed(this.#destroyRef))
      .subscribe(([localFilters, remoteFilters]) => {
        const queryParams: Params = {
          localFilters: JSON.stringify(localFilters),
          remoteFilters: JSON.stringify(remoteFilters),
        };
        this.#updateQueryParams(queryParams).then(() => {
          const queryString = this.#searchQueryParamsToQueryString(
            this.#activatedRoute.snapshot.queryParams as SearchQueryParams,
          );
          this.#authenticationService.patchProductSearchQueryString(queryString).subscribe();
        });
      });
  }

  #updateQueryParams(queryParams: Params): Promise<boolean> {
    return this.#router.navigate([], {
      relativeTo: this.#activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  #searchQueryParamsToQueryString(queryParams: SearchQueryParams): string {
    return Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  }

  #queryStringToSearchQueryParams(queryParams: string): SearchQueryParams {
    return queryParams.split('&').reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      return {
        ...acc,
        [key]: value,
      };
    }, {} as SearchQueryParams);
  }

  // In case filter names change we need to clean up possible deprecated filters
  #cleanupDeprecatedUrlFilters(filtersInQueryParams: Record<string, any>): void {
    for (const filterKey of Object.keys(filtersInQueryParams)) {
      // if filterKey not mantained in enum delete it
      if (!(Object.values(FilterIdEnum) as string[]).includes(filterKey)) {
        delete filtersInQueryParams[filterKey];
      }
    }
  }
}

export interface SearchQueryParams {
  localFilters?: string;
  remoteFilters?: string;
}

export interface SearchParams {
  localFilters: SelectedLocalFilters | null;
  remoteFilters: SelectedRemoteFilters | null;
}
